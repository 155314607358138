<template>
  <v-container fluid>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md6 xl6 lg6>
        <v-card elevation="12">
          <v-app-bar flat color="primary">
            <v-app-bar-nav-icon color="white"></v-app-bar-nav-icon>
            <v-toolbar-title class="title white--text pl-0">
              Reset Password
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="white" icon></v-btn>
          </v-app-bar>
          <v-card-text v-if="resetFail || resetSuccess">
            <v-alert v-show="resetFail" type="error">
              {{ resetFailedMessage }}
            </v-alert>
            <v-row class="mt-2 justify-center">
              <v-btn
                v-show="resetFail"
                color="secondary"
                class="mr-4"
                @click="$router.push({ name: 'forgot' })"
              >
                Resend Email
              </v-btn>
            </v-row>
            <v-alert v-show="resetSuccess" type="success">
              {{ resetSuccessMessage }}
            </v-alert>
            <v-row class="mt-2 justify-center">
              <v-btn
                color="primary"
                v-show="resetSuccess"
                class="mr-4"
                @click="$router.push('/')"
              >
                Return To Login
              </v-btn>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="password"
                :rules="passwordRules"
                label="Password"
                type="password"
                required
              >
              </v-text-field>
              <v-text-field
                v-model="passwordConfirm"
                :rules="passwordRules"
                type="password"
                label="Confirm Password"
                required
              >
              </v-text-field>
              <v-btn
                class="ma-2"
                :loading="loading"
                :disabled="loading || resetSuccess"
                color="primary"
                @click="validate()"
              >
                Submit
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
const axios = require("axios");

export default {
  name: "Reset",

  data: () => ({
    loading: false,
    valid: true,
    passwordRules: [
      v => !!v || "Password is required",
      v => (v && v.length > 8) || "Password must at least 8 characters"
    ],
    password: "",
    passwordConfirm: "",
    resetFail: false,
    resetFailedMessage: "",
    resetSuccess: false,
    resetSuccessMessage: ""
  }),
  created: function() {
    if (this.$store.state.user) {
      this.$router.push("/select");
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();

      if (this.valid && this.password !== this.passwordConfirm) {
        this.valid = false;
        this.resetFail = true;
        this.resetFailedMessage = "Passwords do not match.";
      }

      if (this.valid) this.submitReset();
    },
    submitReset() {
      if (!this.valid) return;

      this.loader = true;
      this.loading = true;

      axios
        .post(`/api/reset/${this.$route.params.token}`, {
          confirm: this.passwordConfirm,
          password: this.password
        })
        .then(res => {
          if (res.status === 200) {
            this.resetFail = false;
            this.resetFailedMessage = "";
            if (res.data.msg) {
              this.resetFail = true;
              this.resetFailedMessage = res.data.msg;
            } else {
              this.resetSuccess = true;
              this.resetSuccessMessage = "Your password has been reset.";
            }
          } else {
            this.resetFail = true;
            this.resetFailedMessage = "Unable to reset password.";
          }
        })
        .catch(() => {
          this.resetFail = true;
          this.resetFailedMessage = "Unable to reset password.";
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
