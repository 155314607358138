<template>
  <reset-pass />
</template>

<script>
import ResetPass from "../components/Reset";

export default {
  name: "Reset",

  components: {
    ResetPass
  }
};
</script>
